import { FC, useEffect, useRef, useState } from 'react'
import { styled } from '@sans-souci/styles'
import { SectionCoverSliderProps } from '@sans-souci/models'
import { Button, Link, PortableText, SectionWrap } from '@sans-souci/components'
import {
  ArrowLeft,
  ArrowLeftShort,
  ArrowRight,
  ArrowRightShort,
} from '@sans-souci/icons'
import { Cover } from './components/Cover'

const SliderButton = styled(Button, {
  position: 'absolute',
  alignSelf: 'center',
  display: 'flex',
  alignItems: 'center',
  height: '$24',
  padding: ' $8',
  zIndex: 1,

  '&:focus-visible': {
    outlineOffset: '-5px',
  },
  '&:disabled': {
    opacity: '0.9',
  },
  ['svg path']: {
    transition: 'fill 500ms',
  },
  '@lg': {
    margin: '$24',
  },
  variants: {
    variant: {
      left: {
        justifySelf: 'start',
      },
      right: {
        justifySelf: 'end',
      },
    },
  },
  '.mobile': {
    '@lg': {
      display: 'none',
    },
  },
  '.desktop': {
    display: 'none',
    '@lg': { display: 'block' },
  },
})

const Main = styled('div', {
  position: 'relative',
  display: 'grid',
  variants: {
    textColor: {
      black: {
        color: '$black',
      },
      white: {
        color: '$beige',
      },
    },
  },
})
const Slide = styled('div', {
  gridArea: '1/1',
  variants: {
    textColor: {
      black: {
        color: '$black',
      },
      white: {
        color: '$beige',
      },
    },
  },
})
const SlideDescriptionsWrap = styled('div', {
  position: 'relative',
  display: 'grid',
  transition: 'height 500ms cubic-bezier(.2,.4,.2,1)',
  overflow: 'hidden',
})
const SlideDescription = styled('div', {
  gridArea: '1/1',
  position: 'absolute',
  justifySelf: 'center',
  textAlign: 'center',
  paddingTop: '$48',
  paddingBottom: '$8',
  variants: {
    transitionState: {
      visible: {
        transition: 'opacity 500ms 400ms',
        opacity: 1,
      },
      hidden: {
        opacity: 0,
        transition: 'opacity 500ms',
        pointerEvents: 'none',
      },
    },
  },
})
const Content = styled('div', {
  opacity: 1,
  maxWidth: '$maxWidthL',
  margin: '0 auto',
  marginBottom: '$40',
})
export const SectionCoverSlider: FC<SectionCoverSliderProps> = ({
  priority,
  slides,
  _key,
}) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const [descriptionHeight, setDescriptionHeight] = useState<
    undefined | number
  >(undefined)
  const refSlideDescriptionsWrap = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const elem = refSlideDescriptionsWrap.current?.children.item(activeIndex)
    if (!elem) return
    setDescriptionHeight(elem.getBoundingClientRect().height)
  }, [activeIndex])

  if (!slides) return null

  const goToPrev = () =>
    setActiveIndex(activeIndex === 0 ? slides.length - 1 : activeIndex - 1)
  const goToNext = () =>
    setActiveIndex(activeIndex + 1 === slides.length ? 0 : activeIndex + 1)

  const textColor = slides[activeIndex].mode === 'default' ? 'black' : 'white'

  return (
    <SectionWrap type={'fullBleed'} id={_key}>
      <Main textColor={textColor}>
        {slides.map((slide, i) => (
          <Slide
            textColor={slide.mode === 'default' ? 'black' : 'white'}
            key={i}
          >
            <Cover
              title={slide.title}
              media={slide.media}
              priority={priority && i === 0}
              mode={slide.mode}
              visible={i === activeIndex}
            />
          </Slide>
        ))}

        <SliderButton onClick={goToPrev} variant={'left'}>
          <ArrowLeft className={'desktop'} />
          <ArrowLeftShort className={'mobile'} />
        </SliderButton>
        <SliderButton onClick={goToNext} variant={'right'}>
          <ArrowRight className={'desktop'} />
          <ArrowRightShort className={'mobile'} />
        </SliderButton>
      </Main>
      <SlideDescriptionsWrap
        ref={refSlideDescriptionsWrap}
        css={{
          height: descriptionHeight ? `${descriptionHeight}px` : undefined,
        }}
      >
        {slides.map((slide, i) => (
          <SlideDescription
            key={i}
            transitionState={i === activeIndex ? 'visible' : 'hidden'}
          >
            <Content>
              {slide.text && <PortableText value={slide.text} />}
            </Content>
            {slide.cta && slide.cta.title && (
              <Link {...slide.cta.payload} appearance={'ButtonPrimaryWhite'}>
                {slide.cta.title}
              </Link>
            )}
          </SlideDescription>
        ))}
      </SlideDescriptionsWrap>
    </SectionWrap>
  )
}
