import { styled } from '@sans-souci/styles'
import { FC, PropsWithChildren } from 'react'

import { Button, useHeightAnimation } from '@sans-souci/components'
import { useTranslation } from 'react-i18next'

export type UtilityAccordionItemProps = {
  itemId?: number
  isActive?: boolean
  className?: string
  onRequestOpen?: (index: number) => void
  onRequestClose?: (index: number) => void
  label: string
}

const AccordionHeader = styled('div', {
  display: 'grid',
  textAlign: 'left',
  padding: '$40 20px',
  borderTop: '1px solid $black',

  '@lg': {
    padding: '$32 30px',
  },
})

const AccordionContent = styled('div', {
  marginBottom: '$16',

  '@lg': {
    marginBottom: '$20',
  },
})

const Label = styled('h3', {
  $projectFont: 'heading03',
  marginBottom: '$16',

  '@lg': {
    marginBottom: '$20',
  },
})

export const UtilityAccordionItem: FC<
  PropsWithChildren<UtilityAccordionItemProps>
> = ({
  itemId,
  isActive = false,
  onRequestClose,
  onRequestOpen,
  label,
  className,
  children,
}) => {
  const { t } = useTranslation('sectionUtilityContent')
  const handleClick = () => {
    if (onRequestClose && onRequestOpen && itemId !== undefined) {
      isActive ? onRequestClose(itemId) : onRequestOpen(itemId)
    }
  }

  const [bodyRef, styles] = useHeightAnimation<HTMLDivElement>({
    isActive,
  })

  return (
    <div
      aria-expanded={isActive}
      aria-controls={`sect${itemId}`}
      id={`accordion-${itemId}`}
      aria-disabled={isActive}
    >
      <AccordionHeader>
        {label && <Label>{label}</Label>}

        <div
          className={className}
          role="region"
          ref={bodyRef}
          style={styles}
          id={`sect${itemId}`}
          aria-labelledby={`accordion-${itemId}`}
        >
          <AccordionContent>{children}</AccordionContent>
        </div>

        <Button onClick={handleClick} appearance={'ButtonText'}>
          {isActive ? t('closeLabel') : t('readMoreLabel')}
        </Button>
      </AccordionHeader>
    </div>
  )
}
