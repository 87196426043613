import { FC } from 'react'
import { styled } from '@sans-souci/styles'
import { SectionUtilityContentProps } from '@sans-souci/models'
import {
  Media,
  EditorialPortableText,
  AccordionItemsList,
  SectionWrap,
} from '@sans-souci/components'
import { UtilityAccordionItem } from './UtilityAccordionItem'

const Root = styled('div', {
  display: 'grid',

  $lin: {
    paddingTop: [100, 180],
  },
  '@lg': {
    gridTemplateColumns: '1fr 1fr',
  },
})

const AccordionWrap = styled('div', {
  height: 'fit-content',
  borderBottom: '1px solid $black',
})
const MediaWrap = styled('div', {
  height: 'fit-content',
  position: 'sticky',
  top: 0,
  display: 'none',

  '@lg': {
    display: 'block',
  },
})

export const SectionUtilityContent: FC<SectionUtilityContentProps> = ({
  media,
  items,
  priority,
  _key,
}) => {
  return (
    <SectionWrap type={'withBorders'} id={_key}>
      <Root>
        {items && items.length > 0 && (
          <AccordionWrap>
            <AccordionItemsList allowMultipleActiveItems={true}>
              {(handleRequestOpen, handleRequestClose, isActive) => {
                return items.map(({ title, description }, index) => {
                  return (
                    <UtilityAccordionItem
                      key={index}
                      isActive={isActive(index)}
                      label={title || ''}
                      itemId={index}
                      onRequestOpen={handleRequestOpen}
                      onRequestClose={handleRequestClose}
                    >
                      {description && (
                        <EditorialPortableText content={description} />
                      )}
                    </UtilityAccordionItem>
                  )
                })
              }}
            </AccordionItemsList>
          </AccordionWrap>
        )}

        {media && (
          <MediaWrap>
            <Media {...media} priority={priority} sizes={'50vw'} />
          </MediaWrap>
        )}
      </Root>
    </SectionWrap>
  )
}
