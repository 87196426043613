import { FC, useEffect, useRef, useState } from 'react'
import { styled } from '@sans-souci/styles'
import { autoTextSize } from 'auto-text-size'
import { Media } from '@sans-souci/components'
import { CoverMode, MediaFieldType } from '@sans-souci/models'

const Root = styled('div', {
  display: 'grid',
  height: '100vh',
  position: 'relative',
  background: '$beige',
  $lin: {
    paddingLeft: 'S',
    paddingRight: 'S',
  },
  variants: {
    isWhite: {
      true: {
        color: 'white',
      },
    },
    transitionState: {
      entering: {
        opacity: 1,
        zIndex: 1,
        transition: 'opacity 500ms',
      },
      entered: {
        zIndex: 1,
      },
      exited: {
        opacity: 0,
      },
      exiting: {
        opacity: 0,
        transition: 'opacity 0ms 500ms',
      },
    },
  },
})

const MediaContainer = styled('div', {
  variants: {
    mode: {
      fullBleedWhite: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      fullBleed: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      default: {
        height: '100%',
        width: '100%',
        maxHeight: '80%',
        maxWidth: '$maxWidthS',
        placeSelf: 'center',
      },
    },
  },
})

const TitleWrap = styled('div', {
  position: 'absolute',
  placeSelf: 'end center',
  width: '100%',
  display: 'grid',
  justifyContent: 'center',
  paddingTop: '$24',
  paddingBottom: '$24',
  paddingLeft: '$containerMarginMobile',
  paddingRight: '$containerMarginMobile',
  $projectFont: 'heading01',
  margin: '0 auto',
  h1: {
    placeSelf: 'center',
    textAlign: 'center',
    lineHeight: 1,
    marginBottom: '-0.22em',
    paddingTop: '0.07em',
    paddingBottom: '0.04em',
  },
  variants: {
    ready: {
      true: {
        opacity: 1,
        transition: 'opacity 1000ms 250ms',
        h1: {
          transition: 'transform 2000ms 0ms cubic-bezier(.2,.4,.2,1)',
          transform: 'none',
        },
      },
      false: {
        opacity: 0,
        transition: 'opacity 0ms 1000ms',
        h1: {
          transition: 'transform 0 1000ms',
          transform: 'translateY(8px)',
        },
      },
    },
  },
})

type Props = {
  media?: MediaFieldType
  title?: string
  mode?: CoverMode
  priority?: boolean
  visible: boolean
}
export const Cover: FC<Props> = ({
  media,
  title,
  mode = 'default',
  priority = false,
  visible,
}) => {
  const titleWrapRef = useRef<HTMLDivElement>(null)
  const [transitionState, setTransitionState] = useState<
    'entered' | 'entering' | 'exited' | 'exiting'
  >(visible ? 'entered' : 'exited')

  const timer = useRef<number | null>(null)

  useEffect(() => {
    if (!titleWrapRef?.current) return
    const updateTextSize = autoTextSize({
      innerEl: titleWrapRef.current.getElementsByTagName('h1')[0],
      maxFontSizePx: 610,
      minFontSizePx: 40,
      mode: 'multiline',
      fontSizePrecisionPx: 1,
      containerEl: titleWrapRef.current,
    })
    updateTextSize()
    return () => {
      updateTextSize.disconnect()
    }
  }, [])

  useEffect(() => {
    if (transitionState === 'exiting' || transitionState === 'entering') {
      timer.current && window.clearTimeout(timer.current)
    }
    if (visible) {
      setTransitionState('entering')
      timer.current = window.setTimeout(() => {
        setTransitionState('entered')
      }, 500)
    } else {
      setTransitionState('exiting')
      timer.current = window.setTimeout(() => {
        setTransitionState('exited')
      }, 500)
    }
  }, [visible])

  return (
    <Root transitionState={transitionState} isWhite={mode === 'fullBleedWhite'}>
      {media && (
        <MediaContainer mode={mode}>
          <Media
            {...media}
            priority={priority}
            layout={'fill'}
            sizes={mode === 'fullBleed' ? '100vw' : '60vw'}
            objectFit={mode === 'default' ? 'contain' : 'cover'}
          />
        </MediaContainer>
      )}
      {title && (
        <TitleWrap
          ref={titleWrapRef}
          ready={
            transitionState === 'entering' ||
            transitionState === 'entered' ||
            transitionState === 'exiting'
          }
        >
          <h1>{title}</h1>
        </TitleWrap>
      )}
    </Root>
  )
}
