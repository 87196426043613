import { FC } from 'react'
import { styled } from '@sans-souci/styles'
import { GenericCard, SectionWrap } from '@sans-souci/components'
import { useFilters } from '@sans-souci/components'
import { Filter } from '@sans-souci/components'
import { SectionProductListingProps } from '@sans-souci/models'

const Root = styled('div', {
  $lin: {
    paddingTop: [150, 180],
    paddingBottom: 'XXL',
  },
})

const FilterWrap = styled('div', {
  padding: '0 $32 $32',
  display: 'flex',
  justifyContent: 'center',

  '@lg': {
    justifyContent: 'end',
  },
})

const NoFoundMessage = styled('div', {
  textAlign: 'center',
  gridColumn: '1/4',
  $projectFont: 'mono01',
})

const CollectionsWrap = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridRowGap: '$40',

  '@md': {
    gridColumnGap: '$32',
    gridRowGap: '$64',
    gridTemplateColumns: '1fr 1fr',
  },

  '@lg': {
    gridRowGap: '$96',
    gridTemplateColumns: '1fr 1fr 1fr',
  },
})

export const SectionProductListing: FC<SectionProductListingProps> = ({
  products,
  priority,
  _key,
}) => {
  const { currentItems, onFilterChange, filters, onSortChange } = useFilters({
    items: products ?? [],
    attributesToFilterBy: ['type', 'family'],
    sortingKeys: ['featured', 'az', 'za'],
    defaultSortKey: 'featured',
  })

  return (
    <SectionWrap type={'withBorders'} id={_key}>
      <Root>
        <FilterWrap>
          {filters && (
            <Filter
              filters={filters}
              currentItems={currentItems}
              onFilterChange={onFilterChange}
              onSortChange={onSortChange}
            />
          )}
        </FilterWrap>

        {currentItems && (
          <CollectionsWrap>
            {currentItems.length === 0 && (
              <NoFoundMessage>No products found</NoFoundMessage>
            )}
            {currentItems.map((item, index) => (
              <GenericCard
                {...item}
                sizes={'(min-width: 1000px) 100vw, 33vw'}
                priority={index <= 3 && priority}
                key={item._id}
                label={'Discover the product'}
              />
            ))}
          </CollectionsWrap>
        )}
      </Root>
    </SectionWrap>
  )
}
