import { errorDebugMessage } from '@sans-souci/utils'
import { useEffect, useState } from 'react'
import { ShopifyResolvedProductExtended } from '@sans-souci/shopify-sdk'
import { ProductOptionProps } from './ProductOption/ProductOption'

type SelectedOptions = Record<string, string>

type ProductPageType = 'SINGLE_VARIANT' | 'MULTIPLE_OPTIONS' | undefined

export const useComputedProductData = (
  productData: ShopifyResolvedProductExtended | undefined,
) => {
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions>({})

  if (!productData) {
    throw new Error('')
  }
  // const currentProductVariants = Object.values(productVariants).filter(
  //   ({ id, availableForSale }) => variantIds.includes(id) && availableForSale,
  // )

  // reset selections on product change
  useEffect(() => {
    setSelectedOptions({})
  }, [productData.id])

  const pageType: ProductPageType = (() => {
    if (productData.variants.length === 1) return 'SINGLE_VARIANT'
    if (productData.options.length >= 1) return 'MULTIPLE_OPTIONS'
    errorDebugMessage('Wrong ProductPageType')
    return undefined
  })()

  const onOptionChange = (value: string, name: string) => {
    setSelectedOptions({ ...selectedOptions, [name]: value })
  }

  const optionsToSelect = (() => {
    switch (pageType) {
      case 'SINGLE_VARIANT': {
        return undefined
      }
      case 'MULTIPLE_OPTIONS': {
        return productData.options.reduce<ProductOptionProps[]>((acc, val) => {
          return [
            ...acc,
            {
              name: val.name,
              values: val.values.map((v) => ({
                optionName: val.name,
                value: v,
                selected: selectedOptions[val.name] === v,
              })),
            },
          ]
        }, [])
      }
      default: {
        return undefined
      }
    }
  })()

  const currentVariant = (() => {
    switch (pageType) {
      case 'SINGLE_VARIANT': {
        return productData.variants[0]
      }
      case 'MULTIPLE_OPTIONS': {
        return productData.variants.find((variant) =>
          variant.selectedOptions.every(
            (o) => selectedOptions[o.name] === o.value,
          ),
        )
      }
      default: {
        return undefined
      }
    }
  })()
  //
  // const currentVariant =
  //   (() => {
  //     if (pageType === 'SINGLE_VARIANT' || !pageType) {
  //       return productVariants[variantIds[0]]
  //     }
  //     if (
  //       pageType === 'MULTIPLE_OPTIONS' &&
  //       productOptions.length === selectedOptions?.length
  //     ) {
  //       //find variant that has same selectedOptions as optionsInQuery
  //       return (
  //         variantIds
  //           .map((id) => productVariants[id])
  //           .find(
  //             (variant) =>
  //               variant.availableForSale &&
  //               variant.selectedOptions.every(
  //                 (option) =>
  //                   selectedOptions?.find((o) => o.name === option.name)
  //                     ?.value === option.value,
  //               ),
  //           ) ?? logger.error('Variant with selected options not found')
  //       )
  //     }
  //     return undefined
  //   })() || undefined
  //
  // const optionsToSelect: ProductOptionProps[] = (() => {
  //   if (pageType === 'MULTIPLE_OPTIONS') {
  //     return productOptions.map((option) => {
  //       return {
  //         id: option.name,
  //         values: option.values.map((value) => {
  //           // if any of selected option has same value as that option it is selected
  //           const selected = selectedOptions?.some(
  //             (selectedOption) =>
  //               selectedOption.name === option.name &&
  //               selectedOption.value === value,
  //           )
  //
  //           const currentOptionIndex = findOptionHierarchyIndex(option)
  //
  //           // Disable option if previous option is not yet selected
  //           const isHierarchicallyDisabled = (() => {
  //             const selectedOptionsIndexes = selectedOptions.map(
  //               findOptionHierarchyIndex,
  //             )
  //
  //             const isOneLowerOptionSelected = selectedOptionsIndexes.includes(
  //               currentOptionIndex - 1,
  //             )
  //
  //             if (currentOptionIndex === 0) {
  //               return false
  //             }
  //
  //             if (isOneLowerOptionSelected) {
  //               return false
  //             }
  //
  //             return true
  //           })()
  //
  //           // find if there is a variant that matches all lower hierarchy selected options and the current option
  //           const isAvailable = (() => {
  //             const lowerCherarchySelecteOptions = selectedOptions.filter(
  //               (o) => findOptionHierarchyIndex(o) < currentOptionIndex,
  //             )
  //
  //             const optionsToMatch: SelectedOptions = [
  //               ...lowerCherarchySelecteOptions,
  //               { name: option.name, value },
  //             ]
  //
  //             // for the first hierarchy we only check if there is any available option in stack
  //             if (currentOptionIndex === 0) {
  //               return currentProductVariants.find(
  //                 (variant) =>
  //                   variant.availableForSale &&
  //                   variant.selectedOptions.some(
  //                     (o) => o.name === option.name && o.value === value,
  //                   ),
  //               )
  //             }
  //
  //             const variantToMatch = currentProductVariants.find(
  //               ({
  //                 selectedOptions: availableOptionCollection,
  //                 availableForSale,
  //               }) =>
  //                 availableForSale &&
  //                 optionsToMatch.every((optionToMatch) =>
  //                   availableOptionCollection.find(
  //                     (o) =>
  //                       o.name === optionToMatch.name &&
  //                       o.value === optionToMatch.value,
  //                   ),
  //                 ),
  //             )
  //
  //             return !!variantToMatch
  //           })()
  //
  //           return {
  //             optionId: option.name,
  //             id: value,
  //             title: value,
  //             selected,
  //             disabled: isHierarchicallyDisabled || !isAvailable,
  //           }
  //         }),
  //       }
  //     })
  //   }
  //   return []
  // })()

  return { currentVariant, optionsToSelect, onOptionChange } as const
}
