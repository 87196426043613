import { FC } from 'react'
import { styled } from '@sans-souci/styles'
import { SectionProjectListingPageProps } from '@sans-souci/models'
import { Filter, ProjectCard, SectionWrap } from '@sans-souci/components'
import { useTranslation } from 'react-i18next'
import { useFilters } from '@sans-souci/components'
const Root = styled('div', {
  paddingTop: 'calc($96 + $headerHeightMobile)',
  '@md': {
    paddingTop: 'calc($120 + $headerHeightDesktop)',
  },

  $lin: {
    paddingBottom: 'XXL',
  },

  variants: {
    darkTheme: {
      true: {
        background: '$black',
        color: '$beige',
      },
      false: {},
    },
  },
})

const FilterWrap = styled('div', {
  padding: '0 $32 $32',
  display: 'flex',
  justifyContent: 'center',

  '@lg': {
    justifyContent: 'end',
  },
})

const ProjectsWrap = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  rowGap: '$40',
  '@md': {
    gridTemplateColumns: 'repeat(2,1fr)',
    columnGap: '$32',
  },
  '@lg': {
    gridTemplateColumns: 'repeat(3,1fr)',
    rowGap: '$96',
  },
})

const NoFoundMessage = styled('div', {
  textAlign: 'center',
  gridColumn: '1/3',
  $projectFont: 'mono01',
})

export const SectionProjectListingPage: FC<SectionProjectListingPageProps> = ({
  projects,
  priority,
  darkTheme = false,
  _key,
}) => {
  const { currentItems, onFilterChange, filters, onSortChange } = useFilters({
    items: projects ?? [],
    attributesToFilterBy: ['type', 'location'],
    sortingKeys: ['featured', 'newest', 'oldest', 'az', 'za'],
    defaultSortKey: 'featured',
  })

  const { t } = useTranslation('filters')

  return (
    <SectionWrap type={'withBorders'} theme={'dark'} id={_key}>
      <Root darkTheme={darkTheme}>
        {filters && (
          <FilterWrap>
            <Filter
              filters={filters}
              currentItems={currentItems}
              onFilterChange={onFilterChange}
              onSortChange={onSortChange}
            />
          </FilterWrap>
        )}
        <ProjectsWrap>
          {currentItems.length === 0 && (
            <NoFoundMessage>{t('noFoundProjects')}</NoFoundMessage>
          )}
          {currentItems?.map((project, index) => (
            <ProjectCard
              key={project._id}
              project={project}
              priority={index <= 3 && priority}
              sizes={'(min-width: 1000px) 100vw, 33vw'}
            />
          ))}
        </ProjectsWrap>
      </Root>
    </SectionWrap>
  )
}
