import { FC } from 'react'
import { styled } from '@sans-souci/styles'
import { SectionLibraryListingProps } from '@sans-souci/models'
import { LibraryCard, SectionWrap, FilterItem } from '@sans-souci/components'
import { useTranslation } from 'react-i18next'
import { useFilters } from '@sans-souci/components'
import { Filter } from '@sans-souci/components'

const Root = styled('div', {
  display: 'grid',
  minHeight: '80vh',
  alignContent: 'start',
  gridRowGap: '$48',
  $lin: {
    paddingTop: [160, 260],
    paddingBottom: 'XXL',
  },
  '@md': {
    gridRowGap: '$72',
  },
})

const FilterWrap = styled('div', {
  padding: '0 $32',
  display: 'grid',
  gridRowGap: '$48',
  alignItems: 'start',
  gridTemplateColumns: '1fr',
  '@md': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
})

const Types = styled('div', {
  display: 'grid',
  gridRowGap: '$12',
  alignItems: 'start',
})

const Filters = styled('div', {
  display: 'grid',
  justifyContent: 'end',
})

const NoFoundMessage = styled('div', {
  textAlign: 'center',
  gridColumn: '1/5',
  $projectFont: 'mono01',
})

const LibraryWrap = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridRowGap: '$40',
  gridColumnGap: '$24',
  padding: '0 $32',
  '@sm': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@lg': {
    gridColumnGap: '$32',
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
})

const TypeButton = styled('button', {
  $projectFont: 'heading02',
  background: 'none',
  justifySelf: 'start',
  textUnderlineOffset: '10px',
  '&:hover': {
    textDecoration: 'underline',
    textDecorationThickness: '1px',
  },
  variants: {
    isActive: {
      true: {
        textDecoration: 'underline',
        textDecorationThickness: '1px',
      },
      false: {},
    },
  },
})

export const SectionLibraryListing: FC<SectionLibraryListingProps> = ({
  libraryItems,
  priority,
}) => {
  const {
    currentItems,
    onFilterChange,
    filters,
    onSortChange,
    activeFilterCounts,
  } = useFilters({
    items: libraryItems ?? [],
    attributesToFilterBy: ['type', 'tags'],
    sortingKeys: ['featured', 'newest', 'oldest', 'az', 'za'],
    defaultSortKey: 'featured',
  })

  const { t } = useTranslation('filters')
  const _filters = filters?.filters

  const types = _filters?.reduce(
    (acc: FilterItem['values'] | undefined, curr) => {
      if (curr.title === 'type') {
        acc = curr.values
      }
      return acc
    },
    undefined,
  )

  const activeTypeCounts = _filters
    ? _filters
        .filter((filter) => filter.title === 'type')
        .map((filter) => filter.values.filter((value) => value.isActive).length)
        .reduce((acc, count) => acc + count, 0)
    : 0

  return (
    <SectionWrap type={'withBorders'}>
      <Root>
        <FilterWrap>
          {types && (
            <Types>
              <TypeButton
                onClick={() => onFilterChange('type', '', true)}
                isActive={
                  activeFilterCounts !== 1 ||
                  (activeFilterCounts === 1 && activeTypeCounts === 0)
                }
              >
                {t('seeAll')}
              </TypeButton>
              {types?.map((type) => (
                <TypeButton
                  onClick={() => {
                    onFilterChange('type', type.slug, true)
                  }}
                  isActive={type.isActive && activeFilterCounts === 1}
                  key={type.slug}
                >
                  {type.title}
                </TypeButton>
              ))}
            </Types>
          )}
          {filters && (
            <Filters>
              <Filter
                filters={filters}
                currentItems={currentItems}
                onFilterChange={onFilterChange}
                onSortChange={onSortChange}
                activeFilterCounts={activeFilterCounts}
              />
            </Filters>
          )}
        </FilterWrap>

        {currentItems && (
          <LibraryWrap>
            {currentItems.length === 0 && (
              <NoFoundMessage>{t('noFoundLibraryItems')}</NoFoundMessage>
            )}
            {currentItems.map((libraryItem, index) => (
              <LibraryCard
                {...libraryItem}
                sizes={
                  '(min-width: 1000px) 33vw, (min-width: 430px) 50vw, 90vw'
                }
                priority={index <= 3 && priority}
                key={libraryItem._id}
              />
            ))}
          </LibraryWrap>
        )}
      </Root>
    </SectionWrap>
  )
}
