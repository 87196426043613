import { FC } from 'react'
import { useMediaQuery } from '@sans-souci/styles'
import { Modal } from '@sans-souci/components'
import { SearchModalContent } from './SearchModalContent'
import { SiteConfiguration } from '@sans-souci/models'

export const SearchModal: FC<{
  searchConfig: SiteConfiguration['search']
  isOpen: boolean
  closeSearch: () => void
}> = ({ searchConfig, isOpen = false, closeSearch }) => {
  const matchesMd = useMediaQuery('md')
  return (
    <Modal
      preventScroll={true}
      isOpen={isOpen}
      onRequestClose={closeSearch}
      size={matchesMd ? 'stretch-x' : 'stretch'}
      position={matchesMd ? 'top' : 'center'}
      transition={matchesMd ? 'slide-from-top' : 'fade'}
    >
      <SearchModalContent
        searchConfig={searchConfig}
        closeSearch={closeSearch}
      />
    </Modal>
  )
}
