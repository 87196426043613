import { FC } from 'react'
import { styled } from '@sans-souci/styles'
import { Cover, SectionWrap } from '@sans-souci/components'
import { SectionHeroProps } from '@sans-souci/models'

const Root = styled('div', {
  display: 'grid',
  height: '100vh',
  color: '$beige',
})

export const SectionHero: FC<SectionHeroProps> = ({
  title,
  media,
  cta,
  description,
  priority,
  _key,
}) => {
  return (
    <SectionWrap type={'fullBleed'} id={_key}>
      <Root>
        <Cover
          title={title}
          description={description}
          cta={cta}
          media={media}
          priority={priority}
          mode={'fullBleed'}
          visible
        />
      </Root>
    </SectionWrap>
  )
}
