import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { SectionSearchProps } from '@sans-souci/models'
import { styled } from '@sans-souci/styles'
import { ProductCard, ProductGrid } from '@sans-souci/components'
import { useInView } from 'react-intersection-observer'
import { useRouter } from 'next/router'
import { getLocaleRegionIdFromPath } from '@sans-souci/utils'
import { ChevronDown } from '@sans-souci/icons'
import { useTranslation } from 'react-i18next'
import { ROUTE_SEARCH } from '@sans-souci/configs'
import {
  DEFAULT_SEARCH_SORT_KEY,
  getShopifySearchResults,
  SEARCH_SORT_KEY_MAP,
  ShopifyProductSortKeys,
} from '@sans-souci/services/shopify-service'
import { SearchInput } from './components/SearchInput'
import debounce from 'lodash/debounce'
import {
  ShopifyPageInfo,
  ShopifyResolvedProductBasic,
} from '@sans-souci/shopify-sdk'

const Observer = styled('div', {
  position: 'relative',
})

const SectionSearchWrap = styled('div', {
  margin: '$24 0 $24 0',
})

const RootWrap = styled('div', {
  display: 'grid',
  gap: '$16',
})

const SearchStatusWrap = styled('div', {
  display: 'grid',
  placeContent: 'center',
  $projectFont: 'dev',
  marginRight: '100px',
  marginLeft: '100px',
  minHeight: '3em',
  color: '$black',
})

const SearchContainer = styled('div', {
  marginLeft: '$16',
  marginRight: '$16',
  '@md': {
    marginLeft: '$32',
    marginRight: '$32',
  },
})

const SearchWrap = styled('div', {
  display: 'grid',
  gap: '$8',
  textAlign: 'center',
  margin: '0 auto',
  '@md': {
    maxWidth: '880px',
  },
})

const SearchUtils = styled('div', {
  display: 'inline-grid',
  gridAutoColumns: 'auto',
  gridAutoFlow: 'column',
  placeContent: 'center start',
  gap: '$24',
  borderTop: '1px solid $black',
  borderBottom: '1px solid $black',
  '@lg': {
    borderTop: 'none',
    borderBottom: '1px solid $black',
  },
})

const Grid = styled(ProductGrid, {
  position: 'relative',
  gridAutoFlow: 'row dense',
  transition: 'opacity 300ms ease-in-out',
})

const SelectWrap = styled('div', {
  $projectFont: 'dev',
  display: 'grid',
  gap: '$4',
  gridTemplateColumns: 'auto auto',
  svg: {
    gridArea: '1 / 2',
    placeSelf: 'center end',
    pointerEvents: 'none',
  },
  padding: '$16',
  '@lg': {
    border: 'none',
  },
})
const SortSelectLabel = styled('label', {
  gridArea: '1 / 1',
  alignSelf: 'center',
})
const SortSelect = styled('select', {
  gridArea: '1 / 2',
  appearance: 'none',
  border: 'none',
  display: 'flex',
  alignItems: 'center',
  $projectFont: 'dev',
  color: '$green_09',
  padding: '0 $24 0 $4',
  cursor: 'pointer',
  background: 'transparent',
})

const Status = styled('div', {
  display: 'grid',
  placeContent: 'center',
  placeItems: 'center',
  textAlign: 'center',
  height: '$16',
  textTransform: 'uppercase',
  $projectFont: 'dev',
  gridGap: '$8',
})

export const SectionSearch: FC<SectionSearchProps> = () => {
  const router = useRouter()
  const { locale, query } = router
  const [, localeId] = getLocaleRegionIdFromPath(locale)
  const { t } = useTranslation('collection')

  const [isRouterReady, setIsRouterReady] = useState(false)
  const searchInputRef = useRef<HTMLInputElement>(null)

  const queryValueFromRouter = Array.isArray(query['q'])
    ? query['q'][0]
    : query['q'] || ''

  const sortKeyFromRouter = (() => {
    const val = Array.isArray(query['sort'])
      ? query['sort'][0]
      : query['sort'] || undefined

    return val && Object.keys(SEARCH_SORT_KEY_MAP).includes(val)
      ? val
      : undefined
  })()

  const computedSortKey = sortKeyFromRouter
    ? SEARCH_SORT_KEY_MAP[sortKeyFromRouter].sortKey
    : SEARCH_SORT_KEY_MAP[DEFAULT_SEARCH_SORT_KEY].sortKey
  const computedReverse = sortKeyFromRouter
    ? SEARCH_SORT_KEY_MAP[sortKeyFromRouter].reverse
    : SEARCH_SORT_KEY_MAP[DEFAULT_SEARCH_SORT_KEY].reverse

  const [searchInputValue, setSearchInputValue] =
    useState<string>(queryValueFromRouter)

  const [state, setState] = useState<{
    isLoading: boolean
    query?: string
    isDirty: boolean
    products: ShopifyResolvedProductBasic[]
    sortKey: ShopifyProductSortKeys
    reverse?: boolean
    pageInfo?: ShopifyPageInfo
  }>({
    isLoading: false,
    query: '',
    isDirty: false,
    sortKey: computedSortKey,
    reverse: computedReverse,
    pageInfo: undefined,
    products: [],
  })
  const [observerRef, isObserverInView] = useInView()

  const loadSearchResults: (loadNextPage?: boolean) => void = async (
    loadNextPage = false,
  ) => {
    // snapshot just to avoid server/app states differences
    const querySnapshot = {
      query: queryValueFromRouter,
      sortKey: computedSortKey,
      reverse: computedReverse,
    }

    setState({
      ...state,
      isLoading: true, // show loader
    })

    const res = await getShopifySearchResults(
      {
        query: querySnapshot.query,
        reverse: querySnapshot.reverse,
        sortKey: querySnapshot.sortKey,
        afterCursor: loadNextPage ? state.pageInfo?.endCursor : undefined,
      },
      localeId,
    )
    if (!res) {
      setState({
        ...state,
        isLoading: false,
      })
      return
    }
    setState({
      ...state,
      isLoading: false,
      sortKey: querySnapshot.sortKey,
      reverse: querySnapshot.reverse,
      query: querySnapshot.query,
      products: loadNextPage
        ? [...state.products, ...res.products]
        : res.products,
      pageInfo: res.pageInfo,
      isDirty: !!sortKeyFromRouter,
    })
  }

  const refineQuery = ({
    value,
    sortKey,
  }: {
    value?: string
    sortKey?: string
  }) => {
    const q = value ?? queryValueFromRouter
    const sort = sortKey ?? sortKeyFromRouter ?? undefined

    router.push(
      {
        pathname: ROUTE_SEARCH,
        query: sort ? { q, sort } : { q },
      },
      undefined,
      {
        shallow: true,
      },
    )
  }

  const debouncedRefineQuery = useCallback(debounce(refineQuery, 250), [query])

  const handleInputChange = (value: string) => {
    setSearchInputValue(value)
  }

  const handleInputResetRequest = () => {
    setSearchInputValue('')
    if (searchInputRef.current) searchInputRef.current?.focus()
  }

  useEffect(() => {
    if (isObserverInView && !state.isLoading && state.pageInfo?.hasNextPage) {
      loadSearchResults(true)
    }
  }, [isObserverInView])

  useEffect(() => {
    if (!router.isReady) return
    setIsRouterReady(router.isReady)

    if (queryValueFromRouter !== '' && queryValueFromRouter !== state.query) {
      setSearchInputValue(queryValueFromRouter)
      loadSearchResults()
    }
  }, [query, router.isReady])

  useEffect(() => {
    if (searchInputValue !== state.query) {
      debouncedRefineQuery({ value: searchInputValue })
    }
    return () => {
      debouncedRefineQuery.cancel()
    }
  }, [searchInputValue])

  return (
    <SectionSearchWrap>
      <RootWrap>
        <SearchContainer>
          <SearchWrap>
            <SearchInput
              value={searchInputValue}
              onResetRequest={handleInputResetRequest}
              onChange={handleInputChange}
              onSubmit={() => refineQuery({ value: searchInputValue })}
              ref={searchInputRef}
            />
            <SearchStatusWrap>
              {state.isLoading
                ? 'loading'
                : state.query &&
                  (state.products.length === 0
                    ? `No results for "${state.query}"`
                    : `Showing results for "${state.query}"`)}
              {isRouterReady &&
                queryValueFromRouter === '' &&
                state.products.length === 0 &&
                'Start typing'}
            </SearchStatusWrap>
          </SearchWrap>
        </SearchContainer>
        {state.products.length > 0 && (
          <>
            <SearchUtils>
              <SelectWrap>
                <SortSelectLabel htmlFor="sort-select">
                  {t('sortBy')}:
                </SortSelectLabel>
                <SortSelect
                  name={'sort'}
                  id={'sort-select'}
                  value={sortKeyFromRouter ?? DEFAULT_SEARCH_SORT_KEY}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    refineQuery({ sortKey: e.target.value })
                  }
                >
                  {Object.keys(SEARCH_SORT_KEY_MAP).map((key) => (
                    <option value={key} key={key}>
                      {t(key)}
                    </option>
                  ))}
                </SortSelect>
                <ChevronDown />
              </SelectWrap>
            </SearchUtils>
            <Grid>
              {state.products?.map((product) => {
                return <ProductCard product={product} key={product.id} />
              })}
            </Grid>
            <Observer ref={observerRef} />
            <Status>
              {state.isLoading ? 'loading...' : <>{t('allProductsLoaded')}</>}
            </Status>
          </>
        )}
      </RootWrap>
    </SectionSearchWrap>
  )
}
