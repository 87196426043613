import dynamic from 'next/dynamic'

export const DynamicSectionSample = dynamic(() =>
  import('./SectionSample/SectionSample').then((mod) => mod.SectionSample),
)

export const DynamicSectionFullWidthMedia = dynamic(() =>
  import('./SectionFullWidthMedia/SectionFullWidthMedia').then(
    (mod) => mod.SectionFullWidthMedia,
  ),
)

export const DynamicSectionHalvesSimple = dynamic(() =>
  import('./SectionHalvesSimple/SectionHalvesSimple').then(
    (mod) => mod.SectionHalvesSimple,
  ),
)

export const DynamicSectionHalvesRich = dynamic(() =>
  import('./SectionHalvesRich/SectionHalvesRich').then(
    (mod) => mod.SectionHalvesRich,
  ),
)

export const DynamicSectionProductGrid = dynamic(() =>
  import('./SectionProductGrid/SectionProductGrid').then(
    (mod) => mod.SectionProductGrid,
  ),
)

export const DynamicSectionPhilosophySlider = dynamic(() =>
  import('./SectionPhilosophySlider/SectionPhilosophySlider').then(
    (mod) => mod.SectionPhilosophySlider,
  ),
)

export const DynamicSectionProductSlider = dynamic(() =>
  import('./SectionProductSlider/SectionProductSlider').then(
    (mod) => mod.SectionProductSlider,
  ),
)

export const DynamicSectionFocus = dynamic(() =>
  import('./SectionFocus/SectionFocus').then((mod) => mod.SectionFocus),
)

export const DynamicSectionGallery = dynamic(() =>
  import('./SectionGallery/SectionGallery').then((mod) => mod.SectionGallery),
)

export const DynamicSectionCrossDiscovery = dynamic(() =>
  import('./SectionCrossDiscovery/SectionCrossDiscovery').then(
    (mod) => mod.SectionCrossDiscovery,
  ),
)

export const DynamicSectionContactForm = dynamic(() =>
  import('./SectionContactForm/SectionContactForm').then(
    (mod) => mod.SectionContactForm,
  ),
)

export const DynamicSectionPromoCard = dynamic(() =>
  import('./SectionPromoCard/SectionPromoCard').then(
    (mod) => mod.SectionPromoCard,
  ),
)

export const DynamicSectionCookieDeclaration = dynamic(() =>
  import('./SectionCookieDeclaration/SectionCookieDeclaration').then(
    (mod) => mod.SectionCookieDeclaration,
  ),
)
