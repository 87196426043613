import { styled } from '@sans-souci/styles'
import { FC } from 'react'
import { OptionValueProps, ProductOptionValue } from './ProductOptionValue'

const Root = styled('fieldset', {
  display: 'grid',
  gap: '$12',
})

const Title = styled('div', {
  $projectFont: 'heading03',
})
const Values = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat( auto-fit, minmax(60px, 90px) )',
  gridGap: '$4',
})

export type ProductOptionProps = {
  name: string
  values?: OptionValueProps[]
  onChange?: (optionValue: string, optionName: string) => void
}

export const ProductOption: FC<ProductOptionProps> = ({
  values,
  onChange,
  name,
}) => {
  return (
    <Root>
      <Title>{name}</Title>
      <Values>
        {values?.map((item) => (
          <ProductOptionValue
            {...item}
            key={`${name}_${item.value}`}
            onChange={onChange}
          />
        ))}
      </Values>
    </Root>
  )
}
