import { FC } from 'react'
import { SectionProductConversionAreaProps } from '@sans-souci/models'
import { styled } from '@sans-souci/styles'
import { useComputedProductData } from './useComputedProductData'
import { Media, ProductPrice, SectionWrap } from '@sans-souci/components'
import { AddToCartButton } from '@sans-souci/cart'
import { errorDebugMessage } from '@sans-souci/utils'
import { ProductOption } from './ProductOption/ProductOption'
const Wrap = styled('div', {
  display: 'grid',
  gap: '$20',
  paddingTop: '$headerHeight',
  $lin: {
    paddingLeft: 'S',
    paddingRight: 'S',
  },
  '@lg': {
    gridTemplateColumns: '2fr 1fr',
  },
})

const ProductGallery = styled('div', {
  display: 'grid',
  gridGap: '$4',
  '@lg': {
    gridTemplateColumns: '1fr 1fr',
  },
})

const ProductDetails = styled('div', {
  position: 'relative',
  marginBottom: '$24',
})
const ProductTitle = styled('h1', {
  $projectFont: 'heading02',
  marginBottom: '$8',
})

const ProductDescription = styled('h1', {
  $projectFont: 'mono01',
  marginBottom: '$20',
})

const PriceWrap = styled('div', {
  $projectFont: 'mono01',
  marginBottom: '$12',
})

export const SectionProductConversionArea: FC<
  SectionProductConversionAreaProps
> = ({ product: productData, _key }) => {
  const { currentVariant, optionsToSelect, onOptionChange } =
    useComputedProductData(productData)

  if (!productData) {
    errorDebugMessage('No product delivered to section')
    return null
  }

  return (
    <SectionWrap type={'fullBleed'} id={_key}>
      <Wrap>
        <ProductGallery>
          {productData.media &&
            productData.media.map((val, index) => {
              if (!val) return null
              return <Media mediaPayload={val} key={`product-media-${index}`} />
            })}
        </ProductGallery>
        <ProductDetails>
          <ProductTitle>{productData.title}</ProductTitle>
          {productData.description && (
            <ProductDescription>{productData.description}</ProductDescription>
          )}

          <PriceWrap>
            <ProductPrice
              priceRange={productData.priceRange}
              compareAtPriceRange={productData.compareAtPriceRange}
            />
          </PriceWrap>
          {optionsToSelect && (
            <div>
              {optionsToSelect?.map((option) => (
                <ProductOption
                  {...option}
                  onChange={onOptionChange}
                  key={option.name}
                />
              ))}
            </div>
          )}
          <AddToCartButton variant={currentVariant} />
        </ProductDetails>
      </Wrap>
    </SectionWrap>
  )
}
