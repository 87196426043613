import { FC, useState } from 'react'
import { styled } from '@sans-souci/styles'
import { SectionHeroSliderProps } from '@sans-souci/models'
import { Button, Cover, SectionWrap } from '@sans-souci/components'
import {
  ArrowLeft,
  ArrowLeftShort,
  ArrowRight,
  ArrowRightShort,
} from '@sans-souci/icons'

const SliderButton = styled(Button, {
  position: 'absolute',
  alignSelf: 'center',
  display: 'flex',
  alignItems: 'center',
  height: '$24',
  padding: ' $8',
  zIndex: 1,

  '&:focus-visible': {
    outlineOffset: '-5px',
  },
  '&:disabled': {
    opacity: '0.9',
  },
  ['svg path']: {
    transition: 'fill 500ms',
  },
  '@lg': {
    margin: '$24',
  },
  variants: {
    variant: {
      left: {
        justifySelf: 'start',
      },
      right: {
        justifySelf: 'end',
      },
    },
  },
  '.mobile': {
    '@lg': {
      display: 'none',
    },
  },
  '.desktop': {
    display: 'none',
    '@lg': { display: 'block' },
  },
})

const Root = styled('div', {
  textAlign: 'center',
  height: '100vh',
  position: 'relative',
  display: 'grid',
  variants: {
    textColor: {
      black: {
        color: '$black',
      },
      white: {
        color: '$beige',
      },
    },
  },
})
const Slide = styled('div', {
  gridArea: '1/1',
  variants: {
    textColor: {
      black: {
        color: '$black',
      },
      white: {
        color: '$beige',
      },
    },
  },
})

export const SectionHeroSlider: FC<SectionHeroSliderProps> = ({
  priority,
  slides,
  _key,
}) => {
  const [activeIndex, setActiveIndex] = useState(0)

  if (!slides) return null

  const goToPrev = () =>
    setActiveIndex(activeIndex === 0 ? slides.length - 1 : activeIndex - 1)
  const goToNext = () =>
    setActiveIndex(activeIndex + 1 === slides.length ? 0 : activeIndex + 1)

  const textColor = slides[activeIndex].mode === 'default' ? 'black' : 'white'

  return (
    <SectionWrap type={'fullBleed'} id={_key}>
      <Root textColor={textColor}>
        {slides.map((slide, i) => (
          <Slide
            textColor={slide.mode === 'default' ? 'black' : 'white'}
            key={i}
          >
            <Cover
              title={slide.title}
              description={slide.description}
              cta={slide.cta}
              media={slide.media}
              priority={priority && i === 0}
              mode={slide.mode}
              visible={i === activeIndex}
            />
          </Slide>
        ))}

        <SliderButton onClick={goToPrev} variant={'left'}>
          <ArrowLeft className={'desktop'} />
          <ArrowLeftShort className={'mobile'} />
        </SliderButton>
        <SliderButton onClick={goToNext} variant={'right'}>
          <ArrowRight className={'desktop'} />
          <ArrowRightShort className={'mobile'} />
        </SliderButton>
      </Root>
    </SectionWrap>
  )
}
