import { FC, useEffect, useState } from 'react'
import { styled } from '@sans-souci/styles'
import { SectionCoverProps } from '@sans-souci/models'
import { Link, PortableText, SectionWrap } from '@sans-souci/components'
import { Cover } from '../SectionCoverSlider/components/Cover'

const Root = styled('div', {
  $$enterTime: '300ms',
  position: 'relative',
  textAlign: 'center',
  variants: {
    mode: {
      default: {
        margin: '0 $containerMarginMobile',
        '@md': {
          margin: '0 $containerMarginDesktop',
        },
      },
      fullBleed: {},
      fullBleedWhite: {},
    },
    loaded: {
      true: { opacity: 1 },
      false: { opacity: 0 },
    },
  },
})
const AboveTheFold = styled('div', { height: '100vh' })
const Content = styled('div', {
  maxWidth: '$maxWidthL',
  margin: '0 auto',
  marginBottom: '$40',
  transition: 'opacity $$enterTime',
})

const BelowTheFold = styled('div', {
  paddingTop: '$48',
  paddingBottom: '$8',
})

export const SectionCover: FC<SectionCoverProps> = ({
  title,
  media,
  cta,
  text,
  content,
  priority,
  mode,
  _key,
}) => {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    setLoaded(true)
  }, [])
  return (
    <SectionWrap type={'fullBleed'} id={_key}>
      <Root loaded={loaded} mode={mode}>
        <AboveTheFold>
          <Cover
            title={title}
            media={media}
            priority={priority}
            mode={mode}
            visible
          />
        </AboveTheFold>
        <BelowTheFold>
          <Content>
            {text && <PortableText value={text} />}
            {/* the "text" is here because of support for legacy sections */}
            {content && <PortableText value={content} />}
          </Content>
          {cta && cta.title && (
            <Link {...cta.payload} appearance={'ButtonPrimaryWhite'}>
              {cta.title}
            </Link>
          )}
        </BelowTheFold>
      </Root>
    </SectionWrap>
  )
}
