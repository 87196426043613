import { FC } from 'react'
import { styled } from '@sans-souci/styles'
import { SectionCollectionListingProps } from '@sans-souci/models'
import { SanityCollectionCard, SectionWrap } from '@sans-souci/components'
import { useTranslation } from 'react-i18next'
import { useFilters } from '@sans-souci/components'
import { Filter } from '@sans-souci/components'

const Root = styled('div', {
  $lin: {
    paddingTop: [150, 180],
    paddingBottom: 'XXL',
  },
})

const FilterWrap = styled('div', {
  padding: '0 $32 $32',
  display: 'flex',
  justifyContent: 'center',

  '@lg': {
    justifyContent: 'end',
  },
})

const NoFoundMessage = styled('div', {
  textAlign: 'center',
  gridColumn: '1/4',
  $projectFont: 'mono01',
})

const CollectionsWrap = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridRowGap: '$40',

  '@md': {
    gridColumnGap: '$32',
    gridRowGap: '$64',
    gridTemplateColumns: '1fr 1fr',
  },

  '@lg': {
    gridRowGap: '$96',
    gridTemplateColumns: '1fr 1fr 1fr',
  },
})

export const SectionCollectionListing: FC<SectionCollectionListingProps> = ({
  collections,
  priority,
  _key,
}) => {
  const { currentItems, onFilterChange, filters, onSortChange } = useFilters({
    items: collections ?? [],
    attributesToFilterBy: ['type'],
    sortingKeys: ['featured', 'az', 'za'],
    defaultSortKey: 'featured',
  })

  const { t } = useTranslation('filters')

  return (
    <SectionWrap type={'withBorders'} id={_key}>
      <Root>
        <FilterWrap>
          {filters && (
            <Filter
              filters={filters}
              currentItems={currentItems}
              onFilterChange={onFilterChange}
              onSortChange={onSortChange}
            />
          )}
        </FilterWrap>

        {currentItems && (
          <CollectionsWrap>
            {currentItems.length === 0 && (
              <NoFoundMessage>{t('noFoundCollections')}</NoFoundMessage>
            )}
            {currentItems.map((collection, index) => (
              <SanityCollectionCard
                {...collection}
                sizes={'(min-width: 1000px) 100vw, 33vw'}
                priority={index <= 3 && priority}
                key={collection._id}
              />
            ))}
          </CollectionsWrap>
        )}
      </Root>
    </SectionWrap>
  )
}
