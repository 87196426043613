import { seoImageLoader } from '@sans-souci/components'
import { PageBuilderSection, PageResponse } from '@sans-souci/models'
import { PageBuilder } from '@sans-souci/page-builder'
import { NextPage } from 'next'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'

export const DefaultPage: NextPage<
  NonNullable<PageResponse<PageBuilderSection[]>>
> = ({
  pageBuilder,
  seo,
  background = 'light',
  shopifyData,
  shopifyProduct,
  shopifyCollection,
  title,
}) => {
  const { asPath } = useRouter()
  const fullUrl = `${process.env['BASE_URL']}${asPath}`
  const seoImage = seoImageLoader(seo?.ogImage, seo?.ogAltImage)
  const seoTitle = seo?.metaTitle ?? title ?? ''

  return (
    <div style={{ backgroundColor: background === 'dark' ? '#1A1919' : '' }}>
      <NextSeo
        titleTemplate={seo?.titleTemplate}
        title={seoTitle}
        description={seo?.metaDescription}
        canonical={fullUrl}
        openGraph={{
          url: fullUrl,
          type: 'website',
          title: seo?.ogTitle,
          description: seo?.ogDescription,
          images: [...(seoImage ? [seoImage] : [])],
        }}
        twitter={{
          cardType: 'summary_large_image',
        }}
      />
      {pageBuilder?.map((section, index) => (
        <PageBuilder
          section={{
            ...section,
            priority: index === 0,
            darkTheme: background === 'dark',
          }}
          shopifyData={shopifyData}
          key={`${section._type}-${index}`}
          shopifyProduct={shopifyProduct}
          shopifyCollection={shopifyCollection}
        />
      ))}
    </div>
  )
}
