import { FC, useRef, useState } from 'react'
import { styled } from '@sans-souci/styles'
import { SiteConfiguration } from '@sans-souci/models'
import { Button, Link } from '@sans-souci/components'
import { Close } from '@sans-souci/icons'
import { useRouter } from 'next/router'
import { SearchInput } from './SearchInput'
import { ROUTE_SEARCH } from '@sans-souci/configs'

const RootWrap = styled('div', {
  position: 'relative',
  display: 'grid',
  height: '100vh',
  padding: '$64 $16 $48',
  backgroundColor: '$white',
  $projectFont: 'body01',
  color: '$black',
  '@md': {
    height: 'auto',
    padding: '$48',
    borderBottom: '1px solid $black',
  },
})

const Root = styled('div', {
  justifySelf: 'center',
  position: 'relative',
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  gap: '$24',
  height: '100%',
  width: '100%',
  '@md': {
    maxWidth: '880px',
    gridTemplateRows: '1fr auto',
  },
})

const SearchWrap = styled('div', {
  width: '100%',
  display: 'grid',
  position: 'relative',
  '&:focus-within': {},
})

const CloseButton = styled(Button, {
  position: 'absolute',
  display: 'grid',
  alignItems: 'center',
  justifySelf: 'end',
  height: '$64',
  padding: '0 $16',
  color: '$black',
  focusStyle: 'default',
})

const ContentWrap = styled('div', {
  display: 'grid',
  gap: '$8',
  gridTemplateRows: 'min-content',
  height: '100%',
  overflow: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',

  '@md': {
    alignContent: 'end',
  },
})

const Title = styled('h2', {
  $projectFont: 'heading02',
})

const SuggestionsList = styled('div', {})
const Suggestion = styled('p', {})

const StyledLink = styled(Link, {
  padding: 0,
  $projectFont: 'dev',
  '&:active': {
    opacity: '0.8',
  },
  '&:hover, &:focus-within, &:focus': {
    textDecorationColor: '$gray300',
  },
  focusStyle: 'default',
})

export const SearchModalContent: FC<{
  searchConfig: SiteConfiguration['search']
  closeSearch: () => void
}> = ({ searchConfig, closeSearch }) => {
  const [searchInputValue, setSearchInputValue] = useState<string>('')
  const searchInputRef = useRef<HTMLInputElement>(null)

  const router = useRouter()

  const handleSearchSubmit = async () => {
    router.push(
      {
        pathname: ROUTE_SEARCH,
        query: {
          q: searchInputValue,
        },
      },
      undefined,
      {
        shallow: false,
      },
    )
  }

  const handleSearchChange = (value?: string) => {
    setSearchInputValue(value || '')
  }

  const handleSearchResetRequest = () => {
    setSearchInputValue('')
    searchInputRef.current?.focus()
  }

  return (
    <RootWrap>
      <CloseButton onClick={closeSearch}>
        <Close />
      </CloseButton>
      <Root>
        <SearchWrap>
          <SearchInput
            value={searchInputValue}
            onResetRequest={handleSearchResetRequest}
            onChange={handleSearchChange}
            onSubmit={handleSearchSubmit}
            ref={searchInputRef}
            key={`searchInputModal`}
          />
        </SearchWrap>
        <ContentWrap>
          {searchConfig?.title && <Title>{searchConfig.title}</Title>}
          {searchConfig?.links && (
            <SuggestionsList>
              {searchConfig.links.map((link, index) => {
                return (
                  <Suggestion key={`searchSuggestion-${index}`}>
                    {link.payload ? (
                      <StyledLink {...link.payload}>{link.title}</StyledLink>
                    ) : (
                      <StyledLink
                        href={{
                          pathname: ROUTE_SEARCH,
                          query: {
                            q: link.title,
                          },
                        }}
                      >
                        {link.title}
                      </StyledLink>
                    )}
                  </Suggestion>
                )
              })}
            </SuggestionsList>
          )}
        </ContentWrap>
      </Root>
    </RootWrap>
  )
}
