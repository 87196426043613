import { styled } from '@sans-souci/styles'
import React, { FC } from 'react'

const Root = styled('div', {
  display: 'grid',
  position: 'relative',
  background: 'none',
})
const LabelWrap = styled('label', {
  backgroundColor: '$grey_05',
  display: 'inline-flex',
  $projectFont: 'body01',
  borderRadius: 10,
  height: 36,
  padding: '0 $4',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  border: '1px solid transparent',
  variants: {
    selected: {
      true: {
        backgroundColor: '$black',
        color: '$white',
      },
    },
    disabled: {
      true: {
        backgroundColor: '$white',
        border: '1px solid',
        color: '$grey500',
        borderColor: 'currentColor',
        cursor: 'default',
      },
      false: {
        '&:hover': {
          backgroundColor: '$black',
          color: '$white',
        },
      },
    },
  },
})

const InputRadio = styled('input', {
  appearance: 'none',
  border: 0,
  position: 'absolute',
})

export type OptionValueProps = {
  optionName: string
  value: string
  selected?: boolean
  disabled?: boolean
  onChange?: (id: string, groupId: string) => void
}

export const ProductOptionValue: FC<OptionValueProps> = ({
  onChange,
  optionName,
  selected,
  value,
  disabled,
}) => {
  const mixedId = `${optionName}_${value}`
  return (
    <Root>
      <InputRadio
        disabled={disabled}
        type={'radio'}
        id={mixedId}
        name={optionName}
        value={value}
        onChange={() => (onChange ? onChange(value, optionName) : null)}
        checked={selected}
      />
      <LabelWrap selected={selected} disabled={disabled} htmlFor={mixedId}>
        {value}
      </LabelWrap>
    </Root>
  )
}
