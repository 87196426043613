import React, {
  ChangeEventHandler,
  FormEventHandler,
  KeyboardEventHandler,
} from 'react'
import { Button } from '@sans-souci/components'
import { styled } from '@sans-souci/styles'
import { DeleteIcon, SearchIcon } from '@sans-souci/icons'

const InputForm = styled('form', {
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  height: '$48',
  gap: '$8',
})

const Input = styled('input', {
  borderRadius: '$rMax',
  backgroundColor: '$gray200',
  height: '$48',
  border: 0,
  margin: 0,
  width: '100%',
  $projectFont: 'heading03',
  padding: '0 $32 0 $32',
})
const InputWrap = styled('div', { position: 'relative', display: 'grid' })

const ResetButton = styled(Button, {
  padding: '0 $16',
  marginRight: '$8',
  color: '$black',
  position: 'absolute',
  height: '100%',
  placeSelf: 'center end',
  zIndex: 1,
  '&:disabled': {
    opacity: 0,
  },
  focusStyle: 'default',
})
const SearchInputSubmit = styled(Button, {
  borderRadius: '$rMax',
  background: '$black',
  width: '$48',
  color: 'white',
  display: 'grid',
  placeContent: 'center',
  focusStyle: 'default',
})

interface SearchInputProps {
  value: string
  onResetRequest?: () => void
  onSubmit?: (val: string) => void
  onChange?: (value: string) => void
  className?: string
  isLoading?: boolean
}

export const SearchInput = React.forwardRef<HTMLInputElement, SearchInputProps>(
  ({ value, onResetRequest, onChange, onSubmit, className }, ref) => {
    const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      const { value } = e.currentTarget
      if (onChange) {
        onChange(value)
      }
    }

    const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
      e.preventDefault()
      if (onSubmit) {
        onSubmit(value)
      }
    }

    const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = (e) => {
      switch (e.key) {
        case 'Escape': {
          if (onResetRequest) {
            onResetRequest()
          }
          break
        }
      }
    }

    const handleCloseBtnClick = (e: any) => {
      e.preventDefault()
      if (onResetRequest) {
        onResetRequest()
      }
    }

    return (
      <InputForm className={className} onSubmit={handleSubmit}>
        <InputWrap>
          <Input
            placeholder={'Search'}
            value={value}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
            ref={ref}
            autoFocus
          />
          <ResetButton
            type={'button'}
            onClick={handleCloseBtnClick}
            disabled={value.length === 0}
          >
            <DeleteIcon />
          </ResetButton>
        </InputWrap>
        <SearchInputSubmit>
          <SearchIcon />
        </SearchInputSubmit>
      </InputForm>
    )
  },
)
