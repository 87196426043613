import { FC } from 'react'

import { PageBuilderSection } from '@sans-souci/models'
import {
  SectionProductConversionArea,
  SectionCover,
  SectionCollectionListing,
  SectionLibraryListing,
  SectionUtilityContent,
  SectionCoverSlider,
  SectionProjectListingPage,
  SectionHero,
  SectionHeroSlider,
  DynamicSectionPromoCard,
  DynamicSectionCrossDiscovery,
  DynamicSectionGallery,
  DynamicSectionContactForm,
  DynamicSectionFocus,
  DynamicSectionPhilosophySlider,
  DynamicSectionProductSlider,
  DynamicSectionProductGrid,
  DynamicSectionHalvesRich,
  DynamicSectionHalvesSimple,
  DynamicSectionFullWidthMedia,
  DynamicSectionSample,
  DynamicSectionCookieDeclaration,
  SectionProductListing,
} from '@sans-souci/sections'

import { SectionSearch } from '@sans-souci/search'
import {
  ShopifyData,
  ShopifyResolvedCollection,
  ShopifyResolvedProductExtended,
} from '@sans-souci/shopify-sdk'

export type PageBuilderProps = {
  section: PageBuilderSection
  shopifyData?: ShopifyData
  shopifyProduct?: ShopifyResolvedProductExtended
  shopifyCollection?: ShopifyResolvedCollection
}

export const PageBuilder: FC<PageBuilderProps> = ({
  section,
  shopifyData,
  shopifyProduct,
}) => {
  /**
   * We use two section import strategies:
   * - Dynamic - in order to minimise the size of bundle chunks, we prefer to import sections dynamically
   * - Static - to prevent the "blink" effect in most exposed sections we use static imports
   */
  switch (section._type) {
    // dynamic sections ======================================
    case 'sectionCookieDeclaration':
      return <DynamicSectionCookieDeclaration {...section} />

    case 'sectionPromoCard':
      return <DynamicSectionPromoCard {...section} />

    case 'sectionCrossDiscovery':
      return <DynamicSectionCrossDiscovery {...section} />

    case 'sectionGallery':
      return <DynamicSectionGallery {...section} />

    case 'sectionContactForm':
      return <DynamicSectionContactForm {...section} />

    case 'sectionFocus':
      return <DynamicSectionFocus {...section} />

    case 'sectionPhilosophySlider':
      return <DynamicSectionPhilosophySlider {...section} />

    case 'sectionProductSlider':
      return <DynamicSectionProductSlider {...section} />

    case 'sectionProductGrid':
      return <DynamicSectionProductGrid {...section} />

    case 'sectionHalvesRich':
      return <DynamicSectionHalvesRich {...section} />

    case 'sectionHalvesSimple':
      return <DynamicSectionHalvesSimple {...section} />

    case 'sectionFullWidthMedia':
      return <DynamicSectionFullWidthMedia {...section} />

    case 'sectionSample':
      return <DynamicSectionSample {...section} shopifyData={shopifyData} />

    // static sections ======================================
    case 'sectionHero':
      return <SectionHero {...section} />

    case 'sectionProjectListingPage':
      return <SectionProjectListingPage {...section} />

    case 'sectionHeroSlider':
      return <SectionHeroSlider {...section} />

    case 'sectionCoverSlider':
      return <SectionCoverSlider {...section} />

    case 'sectionCollectionListing':
      return <SectionCollectionListing {...section} />

    case 'sectionProductListing':
      return <SectionProductListing {...section} />

    case 'sectionLibraryListing':
      return <SectionLibraryListing {...section} />

    case 'sectionCover':
      return <SectionCover {...section} />

    case 'sectionUtilityContent':
      return <SectionUtilityContent {...section} />

    case 'sectionProductConversionArea':
      return (
        <SectionProductConversionArea
          {...section}
          shopifyData={shopifyData}
          product={shopifyProduct}
        />
      )
    case 'sectionSearch':
      return <SectionSearch {...section} />
    default:
      return null
  }
}
